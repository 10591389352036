<template>
  <div class="login">
    <div id="content">
      <h1>用户登录</h1>
      <p>
        <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
      </p>
      <p>
<!--        <label for="name"></label>-->
        <input type="text" id="name" v-model="userName" placeholder="用户名">
      </p>
      <p>
<!--        <label for="password"></label>-->
        <input type="password" id="password" v-model="passWord" placeholder="密码">
      </p>
      <p>
        <button @click="login">登录</button>
      </p>
    </div>
  </div>
</template>

<script>
import {reqLogin} from "@/network/home";

export default {
  inject:['privilege','authorization','username','setPrivilege'],
  name: "Login",
  data() {
    return {
      userName: '',
      passWord: '',
      resMsg: '',
      Auth:1,
    }
  },
  methods: {
    login() {
      reqLogin(this.userName, this.passWord).then(res => {
        if (res) {
          console.log(res);
          if ('code' in res) {
            if (res.code == 1000) {
              // console.log(res.data.Token);
              window.sessionStorage.setItem('Authorization', res.data.Token)
              window.sessionStorage.setItem('userName', res.data.UserName)
              window.sessionStorage.setItem('Privilege', res.data.Privilege)
              window.sessionStorage.setItem('id', res.data.id)
              if (res.data.Token){
                this.Auth=1;
              }
              // console.log(this.Auth);
              this.setPrivilege(res.data.Privilege,this.Auth, res.data.UserName);
              this.$router.push('/')
            } else {
              this.resMsg = res.msg
            }
          }
        } else {
          this.resMsg = "接口访问失败"
        }
      })
    }
  }
}
</script>

<style scoped>
.content {
  width: 60%;
  margin: 20px auto;
}

.content input {
  width: 60%;
  line-height: 30px;
  margin-bottom: 6px;
}

.content button {
  /*color: darkgreen;*/
  /*background-color: chartreuse;*/
}

</style>