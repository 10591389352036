import {request} from "@/network/request";


export function VoteUP(VoteName,MatchID,MatchName,Money,AdminName){
    return request({
        method:'post',
        url:'/api/Vote',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            VoteName:VoteName,
            MatchID:MatchID,
            MatchName:MatchName,
            Money:Money,
            AdminName:AdminName,
        })
    })
}

export function GetSponsorData(){
    return request({
        method:'get',
        url:'/api/Sponsor',
    })
}