<template>
  <div id="content">
    <h1>添加选手比赛记录</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <label for="MatchName">赛事名称:</label>
      <select v-model="MatchName" name="selectedMatchName" id="selectedMatchName">
        <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
          {{ item.MatchName }}
        </option>
      </select>
    </p>
    <p>
      <label for="MatchName">比赛场次:</label>
      <input type="number" v-model="Bo" placeholder="比赛场次" id="MatchName">
    </p>
    <p>
      <label for="nicknames">选手1:</label>
      <select v-model="Player1" name="nicknames" id="nicknames">
        <option v-for="user in userData" :key="user.id" :value="user.NickName">
          {{ user.NickName }}
        </option>
      </select>

    </p>
    <p>
      <label for="Score1">选手1比分:</label>
      <input type="number" v-model="Score1" placeholder="选手1比分" id="Score1">
    </p>
    <p>
      <label for="selectedNickName">选手2:</label>
      <select v-model="Player2" name="selectedNickName" id="selectedNickName">
        <option v-for="user in userData" :key="user.id" :value="user.NickName">
          {{ user.NickName }}
        </option>
      </select>
    </p>
    <p>
      <label for="Score2">选手2比分:</label>
      <input type="number" v-model="Score2" placeholder="选手2比分" id="Score2">
    </p>
    <p>
      <button @click="MatchLogUp">提交比赛记录</button>
    </p>
  </div>
</template>

<script>
import {MatchLog} from "@/network/PlayerSignUp";

import {ref} from 'vue'
import {reqGetData, reqGetUserData} from "@/network/home";
// import * as events from "events";

let userData = ref([])
let matchNameData = ref([])
let matchPlayer = ref([])
let matchLog = ref([])

export default {
  name: "MatchLog",
  created() {
    this.getData()
  },
  data(){
    let resMsg;
    return{
      resMsg:'',
      Bo:0,
      Player1Id:'',
      UserName1:'',
      Player1:'',
      Score1:0,
      Race1:'',
      Level1:'',
      Player2Id:'',
      UserName2:'',
      Player2:'',
      Score2:0,
      Race2:'',
      Level2:'',
      MatchId:'',
      MatchName:'',
      Result:'',
      userData,
      matchNameData,
      matchPlayer,
      matchLog,
      admin:'',
    }
  },
  watch: {
    // 监听 selectedNickName 的变化
    Player1(newNickName) {
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newNickName);
      // 如果找到了用户，更新 selectedUserId
      this.Player1Id = user ? user.id : null;
      this.Race1=user?user.Race:null;
      this.Level1=user?user.Level:null;
      this.UserName1=user?user.UserName:null;
      // console.log(this.Player1Id,this.Race1,this.Level1)
    },
    Player2(newNickName) {
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newNickName);
      // 如果找到了用户，更新 selectedUserId
      this.Player2Id = user ? user.id : null;
      this.Race2=user?user.Race:null;
      this.Level2=user?user.Level:null;
      this.UserName2=user?user.UserName:null;
      // console.log(this.Player2Id,this.Race2,this.Level1)
    },
    MatchName(newMatchName) {
      // 查找对应的用户对象
      const match = this.matchNameData.find(match => match.MatchName === newMatchName);
      // 如果找到了用户，更新 selectedUserId
      this.MatchId = match ? match.id : null;
      // console.log(this.MatchId)
    },
    Score1() {
      // 查找对应的用户对象
      if(this.Bo>0&& this.Score1>=0&&this.Score1<=this.Bo){
        this.resMsg=""
        this.Score2=this.Bo-this.Score1
        if (this.Score1>this.Score2){
          this.Result=1
        }else {
          this.Result=0
        }
      }else {
        this.resMsg="比赛场次1输入不匹配，请检查您的输入"
      }
      // console.log(this.Score1)
    },
    Score2() {
      // 查找对应的用户对象
      if(this.Bo>0&& this.Score2>=0&&this.Score2<=this.Bo){
        this.resMsg=""
        this.Score1=this.Bo-this.Score2
        if (this.Score1>this.Score2){
          this.Result=1
        }else {
          this.Result=0
        }
      }else {
        this.resMsg="比赛场次2输入不匹配，请检查您的输入"
      }
      // console.log(this.Result)
    }
  },
  methods:{
    MatchLogUp(){
      if (this.UserName1==this.UserName2){
        this.resMsg="必须填写不同的选手"
      }else{
        this.resMsg=""
        MatchLog(this.Bo,this.Player1Id,this.UserName1,this.Player1,this.Score1,this.Race1,this.Level1,this.Player2Id,this.UserName2,this.Player2,this.Score2,this.Race2,this.Level2,this.MatchId,this.MatchName,this.Result,this.admin).then(res=>{
          // console.log(res)
          if(res.code==1000){
            this.resMsg="比赛记录添加成功"
          }else {
            this.resMsg="比赛记录添加失败"
          }
        })
      }
      // console.log(this.Bo, this.Player1Id, this.Player1, this.Score1, this.Race1,this.Level1, this.Player2Id, this.Player2, this.Score2, this.Race2,this.Level2, this.MatchId, this.MatchName, this.Result);
    },
    getData() {
      reqGetUserData().then(res => {
        this.admin = window.sessionStorage.getItem('userName');
        // console.log(this.admin);
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              userData.value=res.data.ReturnUserMsg
              matchNameData.value=res.data.ReturnMatchName
              matchPlayer.value=res.data.ReturnMatchPlayer
              matchLog.value=res.data.ReturnMatchLog
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
  }
}
</script>

<style>
#content {
  max-width: 600px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  padding: 20px; /* 内边距 */
}

#content h1 {
  text-align: center; /* 标题居中 */
}

#content p {
  margin-bottom: 15px; /* 每个段落（即表单元素）之间的外边距 */
}

#content label {
  display: inline-block;
  margin-right: 10px; /* 标签后的间隔 */
  width: 100px; /* 标签宽度 */
  text-align: right; /* 标签内文字右对齐 */
}

/*#content input[type="number"],*/
#content select{
  width: calc(80% - 120px); /* 输入框和选择框的宽度 */
  /*width: 360px; !* 输入框和选择框的宽度 *!*/
  padding: 5px; /* 内边距 */
}
#content input{
  width: calc(80% - 128px);
  height: 23px;
}

#content button {
  display: block; /* 块级元素 */
  width: 100%; /* 宽度 */
  padding: 10px; /* 内边距 */
  margin-top: 10px; /* 上外边距 */
  background-color: #007bff; /* 背景颜色 */
  color: white; /* 文字颜色 */
  border: none; /* 无边框 */
  border-radius: 5px; /* 边框圆角 */
  cursor: pointer; /* 鼠标悬停时的指针形状 */
}

#content button:hover {
  background-color: #0056b3; /* 按钮悬停时的背景颜色 */
}

</style>