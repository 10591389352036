<template>
  <div id="content">
    <h1>赞助商</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <label for="MatchName">赛事名称:</label>
      <select v-model="MatchName" name="selectedMatchName" id="selectedMatchName">
        <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
          {{ item.MatchName }}
        </option>
      </select>
    </p>
    <p>
      <label for="VoteName">赞助商:</label>
      <input v-model="VoteName" name="VoteName" id="VoteName">
    </p>
    <p>
      <label for="nicknames">赞助金额:</label>
      <input v-model="Money" name="Money" type="number" id="Money" placeholder="{{Money}}">
    </p>
    <p>
      <button @click="MoneyUp">提交</button>
    </p>
  </div>
</template>

<script>

import {ref} from 'vue'
import {PtoMatchUP, reqGetUserData} from "@/network/home";
import {VoteUP} from "@/network/Money";
// import * as events from "events";

let userData = ref([])
let matchNameData = ref([])
let matchPlayer = ref([])
let matchLog = ref([])

export default {
  name: "Vote",
  created() {
    this.getData()
  },
  data(){
    let resMsg;
    return{
      resMsg:'',
      VoteName:'',
      MatchID:'',
      MatchName:'',
      Result:'',
      userData,
      matchNameData,
      matchPlayer,
      matchLog,
      admin:'',
      privilege:0,
      username:'',
      id:0,
      Money:0,
    }
  },
  watch: {
    MatchName(newMatchName) {
      // 查找对应的用户对象
      const match = this.matchNameData.find(match => match.MatchName === newMatchName);
      // 如果找到了用户，更新 selectedUserId
      this.MatchID = match ? match.id : null;
      // console.log(this.MatchID)
    },
  },
  methods:{
    getData() {
      reqGetUserData().then(res => {
        this.admin = window.sessionStorage.getItem('userName');
        this.privilege = window.sessionStorage.getItem('Privilege');
        // this.username = window.sessionStorage.getItem('userName');
        this.id = window.sessionStorage.getItem('id');
        this.id = this.id ? Number(this.id) : 0;
        // console.log(this.admin);
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              userData.value=res.data.ReturnUserMsg
              matchNameData.value=res.data.ReturnMatchName
              matchPlayer.value=res.data.ReturnMatchPlayer
              matchLog.value=res.data.ReturnMatchLog
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
    MoneyUp(){
      // console.log(this.VoteName,this.MatchID,this.MatchName,this.Money,this.admin)
      VoteUP(this.VoteName,this.MatchID,this.MatchName,this.Money,this.admin).then(res=>{
        if('code' in res){
          console.log(res);
          if(res.code==1000){
            this.resMsg="成功提交赞助信息"
          }else {
            this.resMsg=res.msg;
          }
        }
      })
    }
  }
}
</script>

<style>
input[readonly] {
  background-color: #C9D3DD; /* 设置背景颜色为灰色 */
  color: #333; /* 设置文字颜色 */
  cursor: not-allowed; /* 设置鼠标样式为不可用 */
}
</style>