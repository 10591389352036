<template>
  <div id="content">
    <h1>选手报名</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <label for="MatchName">赛事名称:</label>
      <select v-model="MatchName" name="selectedMatchName" id="selectedMatchName">
        <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
          {{ item.MatchName }}
        </option>
      </select>
    </p>
    <p v-show="privilege==0">
      <label for="nicknames">选手:</label>
      <input v-model="UserName1" name="nicknames" id="nicknames" placeholder="{{UserName1}}" readonly>
    </p>
    <p v-show="privilege==1">
      <label for="nicknames">选手:</label>
      <select v-model="Player1" name="nicknames" id="nicknames">
        <option v-for="user in userData" :key="user.id" :value="user.NickName">
          {{ user.NickName }}
        </option>
      </select>
    </p>
    <p>
      <button @click="PlayerMatchUp">报名</button>
    </p>
  </div>
</template>

<script>

import {ref} from 'vue'
import {PtoMatchUP, reqGetUserData} from "@/network/home";
// import * as events from "events";

let userData = ref([])
let matchNameData = ref([])
let matchPlayer = ref([])
let matchLog = ref([])

export default {
  name: "PtoMatch",
  created() {
    this.getData()
  },
  data(){
    let resMsg;
    return{
      resMsg:'',
      Player1Id:0,
      UserName1:'',
      Player1:'',
      MatchId:'',
      MatchName:'',
      Result:'',
      userData,
      matchNameData,
      matchPlayer,
      matchLog,
      admin:'',
      privilege:0,
      // username:'',
      id:0,
    }
  },
  watch: {
    // 监听 selectedNickName 的变化
    Player1(newNickName) {
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newNickName);
      // 如果找到了用户，更新 selectedUserId
      this.Player1Id = user ? user.id : null;
      this.UserName1=user?user.UserName:null;
      console.log(this.Player1Id,this.UserName1)
    },
    MatchName(newMatchName) {
      // 查找对应的用户对象
      const match = this.matchNameData.find(match => match.MatchName === newMatchName);
      // 如果找到了用户，更新 selectedUserId
      this.MatchId = match ? match.id : null;
      // console.log(this.MatchId)
    },
  },
  methods:{
    getData() {
      reqGetUserData().then(res => {
        this.admin = window.sessionStorage.getItem('userName');
        this.privilege = window.sessionStorage.getItem('Privilege');
        // this.username = window.sessionStorage.getItem('userName');
        this.id = window.sessionStorage.getItem('id');
        this.id = this.id ? Number(this.id) : 0;
        if (this.privilege==0){
          this.UserName1=this.admin
          this.Player1Id=this.id
          console.log(this.UserName1,this.Player1Id);
        }
        // console.log(this.admin);
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              userData.value=res.data.ReturnUserMsg
              matchNameData.value=res.data.ReturnMatchName
              matchPlayer.value=res.data.ReturnMatchPlayer
              matchLog.value=res.data.ReturnMatchLog
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
    PlayerMatchUp(){
      // console.log(this.Player1Id,this.UserName1,this.MatchId,this.MatchName,this.admin)
       PtoMatchUP(this.Player1Id,this.UserName1,this.MatchId,this.MatchName,this.admin).then(res=>{
         if('code' in res){
           console.log(res);
           if(res.code==1000){
             this.resMsg="报名成功"
           }else {
             this.resMsg=res.msg;
           }
         }
})
    }
  }
}
</script>

<style>
input[readonly] {
  background-color: #C9D3DD; /* 设置背景颜色为灰色 */
  color: #333; /* 设置文字颜色 */
  cursor: not-allowed; /* 设置鼠标样式为不可用 */
}
</style>