<template>
  <div id="content">
    <h1>添加选手</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <input type="text" placeholder="选手ID" id="NickID" v-model="NickID">
    </p>
    <p>
      <input type="text" placeholder="选手昵称" id="NickName" v-model="NickName">
    </p>
    <p>
<!--      <input type="text" v-model="Level" placeholder="评级">-->
      <select name="Level" id="Level" v-model="Level">
        <option value="天阶">天阶</option>
        <option value="地阶">地阶</option>
        <option value="玄阶">玄阶</option>
        <option value="黄阶">黄阶</option>
      </select>
    </p>
    <p>
      <select name="Race" id="Race" v-model="Race">
        <option value="T">人族(T)</option>
        <option value="P">神族(P)</option>
        <option value="Z">虫族(Z)</option>
      </select>
<!--      <input type="text" v-model="Race" placeholder="种族">-->
    </p>
    <p>
      <button @click="SignUp">确认</button>
    </p>
  </div>
  <div class="player_table">
      <template v-for="(item,index) in userData" :key="index" >
        <li>{{ item.NickName }}</li>
      </template>
  </div>
</template>

<script>
import {PlayerSignUp} from "@/network/PlayerSignUp";
import {reqGetUserData} from "@/network/home";
import {ref} from "vue";
let userData = ref([])
let matchNameData = ref([])
let matchPlayer = ref([])
let matchLog = ref([])
export default {
  name: "PlayerSignUp",
  created() {
    this.getData()
  },
  data(){
    let resMsg;
    return{
      resMsg:'',
      NickID:'',
      NickName:'',
      Level:'',
      Race:'',
      admin:'',
      userData,
    }
  },
  methods:{
    getData() {
      reqGetUserData().then(res => {
        this.admin = window.sessionStorage.getItem('userName');
        // console.log(this.admin);
        if (res){
          console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              userData.value=res.data.ReturnUserMsg
              matchNameData.value=res.data.ReturnMatchName
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
    SignUp(){
      this.admin = window.sessionStorage.getItem('userName');
      // console.log(this.admin);
      PlayerSignUp(this.NickID,this.NickName,this.Level,this.Race,this.admin).then(res=>{
        console.log(res)
        if(res.code==1000){
          this.resMsg="选手添加成功"
        }else {
          this.resMsg="选手添加失败"
        }
      })
    }
  }
}
</script>

<style scoped>
.player_table{
  max-width: 60%;
  margin: 0 auto;
}
li{
  color: #C9D3DD;
  padding: 0 10px;
}
</style>