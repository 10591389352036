<template>
  <div class="home">
    <div id="home_content">
      <div id="home_nav">
        <li :style="{ flexBasis: itemWidth }">
          <span @click="calculateRankings(matchLog), setActive('total')" :class="{ 'active-tab': activeClass == 'total' }">总榜</span>
        </li>
        <li v-for="group in MatchBoArr" :key="group.Bo" :style="{ flexBasis: itemWidth }">
          <span @click="calculateRankings(group.Matches), setActive(group.Matches)" :class="{ 'active-tab': activeClass == group.Matches }">{{ group.Bo }}</span>
        </li>
        <li :style="{ flexBasis: itemWidth }">
          <span @click="setActiveMatch('matchInfo')" :class="{ 'active-tab': activeClass == 'matchInfo' }">赛事详情</span>
        </li>
        <li :style="{ flexBasis: itemWidth }">
          <span @click="setActiveTable('playerMsg')" :class="{ 'active-tab': activeClass == 'playerMsg' }">选手详情</span>
        </li>
      </div>
      <div v-show="activeTable===1">
        <table>
          <tr>
            <th>选手ID</th>
            <th>种族</th>
            <th>排名</th>
<!--            <th>积分</th>-->
            <th>胜</th>
            <th>负</th>
            <th>胜率</th>
          </tr>
          <template v-for="(item,index) in playerScore" :key="index" >
            <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
              <td :style="{ color: getColor(item.Level) }">{{ item.UserName }}</td>
              <td :style="{ color: getColor(item.Level) }">{{ item.Race }}</td>
              <td :style="{ color: getColor(item.Level) }">{{item.index}}</td>
<!--              <td :style="{ color: getColor(item.Level) }">{{ item.score }}</td>-->
              <td :style="{ color: getColor(item.Level) }">{{ item.score }}</td>
              <td :style="{ color: getColor(item.Level) }">{{ item.lose }}</td>
              <td :style="{ color: getColor(item.Level) }">{{ item.Chance }}%</td>
            </tr>
          </template>
        </table>
      </div>
      <div v-show="activeTable===2">
        <div id="content">
          <h1>请选择要查询的选手</h1>
          <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
          <!--        <label for="SelectPlayerNickName">选手</label>-->
          <select v-model="SelectPlayerNickName" name="SelectPlayerNickName" id="SelectPlayerNickName">
            <option v-for="user in userData" :key="user.id" :value="user.NickName">
              {{ user.NickName }}
            </option>
          </select>
          <p>
            <button @click="SendQueryMsg">确认</button>
          </p>
        </div>
        <div v-show="activeUserTable==1">
          <!--        选手信息-->
          <div>
            <table>
              <tr>
                <th>选手ID</th>
                <th>昵称/姓名</th>
                <th>种族</th>
              </tr>
              <template v-for="(item,index) in userDataResult" :key="index">
                <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
                  <td>{{ item.UserName }}</td>
                  <td>{{ item.NickName }}</td>
                  <td>{{ item.Race }}</td>
                </tr>
              </template>
            </table>
          </div>
          <!--        Bo的胜率-->
          <div>
            <table>
              <tr>
                <th>Bo胜率</th>
                <th>场次</th>
                <th>胜</th>
                <th>负</th>
                <th>胜率</th>
              </tr>
              <template v-for="(item,index) in UserBoScore" :key="index">
                <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
                  <td>{{ item.Attrib }}</td>
                  <td>{{ item.total }}</td>
                  <td>{{ item.win }}</td>
                  <td>{{ item.total-item.win }}</td>
                  <td>{{ item.winRate }}</td>
                </tr>
              </template>
            </table>
          </div>
          <!--        种族对抗的胜率-->
          <div>
            <table>
              <tr>
                <th>对抗</th>
                <th>对局数</th>
                <th>胜</th>
                <th>负</th>
                <th>胜率</th>
              </tr>
              <template v-for="(item,index) in UserScore" :key="index">
                <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
                  <td>{{ item.Confrontation }}</td>
                  <td>{{ item.score+item.lose }}</td>
                  <td>{{ item.score }}</td>
                  <td>{{ item.lose }}</td>
                  <td>{{ item.Chance }}</td>
                </tr>
              </template>
            </table>
          </div>
          <!--        排行榜信息-->
          <div>
            <table>
              <tr>
                <th>类型</th>
                <th>积分排名</th>
                <th>积分</th>
                <th>对局数</th>
                <th>胜</th>
                <th>负</th>
                <th>胜率</th>
              </tr>
              <template v-for="(item,index) in UserResultData" :key="index">
                <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
                  <td>{{ item.Attrib }}</td>
                  <td>{{ item.index}}</td>
                  <td>{{ item.score }}</td>
                  <td>{{ item.score+item.lose }}</td>
                  <td>{{ item.score }}</td>
                  <td>{{ item.lose }}</td>
                  <td>{{ item.Chance }}</td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
      <div v-show="activeTable===3">
        <div class="content">
          <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
          <li><span>赛事列表：</span></li>
          <li v-for="group in MatchIDArr" :key="group.MatchName">
            <span @click="calculateRankings(group.Matches),  setActiveMatch(group.Matches)" :class="{ 'active-tab': activeClass == group.Matches }"> {{ group.MatchName }}</span>
          </li>
        </div>
        <div class="content">
          <table v-show="activeTable===3">
            <tr>
              <th>选手ID</th>
              <th>种族</th>
              <th>排名</th>
              <!--            <th>积分</th>-->
              <th>胜</th>
              <th>负</th>
              <th>胜率</th>
            </tr>
            <template v-for="(item,index) in playerScore" :key="index" >
              <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
                <td :style="{ color: getColor(item.Level) }">{{ item.UserName }}</td>
                <td :style="{ color: getColor(item.Level) }">{{ item.Race }}</td>
                <td :style="{ color: getColor(item.Level) }">{{item.index}}</td>
                <!--              <td :style="{ color: getColor(item.Level) }">{{ item.score }}</td>-->
                <td :style="{ color: getColor(item.Level) }">{{ item.score }}</td>
                <td :style="{ color: getColor(item.Level) }">{{ item.lose }}</td>
                <td :style="{ color: getColor(item.Level) }">{{ item.Chance }}%</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import {reqGetData, UserQuery} from "@/network/home";
import events from "events";
import index from "vuex";

import {ref} from 'vue'
import {MatchLog} from "@/network/PlayerSignUp";
// import * as events from "events";

let userData = ref([])


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    index() {
      return index
    },
    events() {
      return events
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    itemWidth() {
      return `calc(100% / ${this.columns})`;
    }
  },
  created() {
    // if (!window.sessionStorage.getItem('Authorization')&&!window.sessionStorage.getItem('Privilege')) {
    //   this.$router.push('/login')
    // }else {
    //   this.Privilege=window.sessionStorage.getItem('Privilege')
    // }
    // this.getPrivilege();
    this.getData()
  },
  data() {
    return {
      resMsg:'',
      userData,
      userDataArr:[],
      userDataResult:[],
      matchNameData:[],
      matchPlayer:[],
      matchLog:[],
      playerScore:[],
      UserScore:[],
      UserBoScore:[],
      UserResultData:[],
      MatchIDArr:[],
      MatchBoArr:[],
      MatchConfArr:[],
      MatchBo2Arr:[],
      ResultArr:[],
      selectedMatchRankings:[],
      // Privilege:'',

      activeTable:1,
      activeClass:'total',
      activeUserTable:0,
      activeMatchTable:0,
      SelectPlayerNickName:'',
      columns:6,
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 800) {
        this.columns = 6;
      } else if (window.innerWidth < 600) {
        this.columns = 3;
      } else {
        this.columns = 4; // 默认情况下显示4列
      }
    },
    getColor(level) {
      switch (level) {
        case "天阶":
          return "#c87531"; // Red
        case "玄阶":
          return "#3752fd";
        case "地阶":
          // return "#9876aa";
          return "#8f07fd";
          // return "purple";
        case "黄阶":
          return "#cece00";
        default:
          return "white";
      }
    },
    setActive(tabId){
      this.activeClass=tabId;
      this.activeTable=1;
    },
    setActiveTable(tabId){
      this.activeClass=tabId;
      this.activeTable=2;
    },
    setActiveMatch(tabId){
      this.activeClass=tabId;
      this.activeTable=3;
    },
    getData() {
      reqGetData().then(res => {
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              // window.sessionStorage.setItem('DataStatus', "1");
              userData.value=res.data.ReturnUserMsg;
              this.userDataArr=res.data.ReturnUserMsg;
              this.matchNameData.value=res.data.ReturnMatchName;
              this.matchPlayer.value=res.data.ReturnMatchPlayer;
              this.matchLog=res.data.ReturnMatchLog;
              // console.log(res.data.ReturnMatchLog);
              // console.log(this.matchLog);

              // 遍历每个比赛记录，记录分数排名
              if(this.matchLog){
                this.calculateRankings(this.matchLog);
                // this.playerScore= this.playerScores(this.matchLog);
                // console.log(scoresArray)
                this.MatchIDArr=this.groupedByMatchId(res.data.ReturnMatchLog);
                this.MatchBoArr=this.groupedByBo(res.data.ReturnMatchLog);
                // console.log(this.MatchBoArr);
              }
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
    //计算小分胜率
    formatWinRate(score, lose) {
      if (score + lose === 0) return '0%'; // 避免除以零
      const winRate = (score / (score + lose)) * 100;
      // return winRate.toFixed(2) + '%'; // 保留两位小数并添加百分号
      return parseFloat(winRate.toFixed(2)); // 保留两位小数并添加百分号
    },
    //计算bo胜率
    forBoWinRate(total, win) {
      if (total === 0) return '0%'; // 避免除以零
      const winRate = (win / total) * 100;
      return winRate.toFixed(2) + '%'; // 保留两位小数并添加百分号
    },
    // 按MatchId分组
    groupedByMatchId(ReturnMatchLog) {
      const grouped = ReturnMatchLog.reduce((acc, match) => {
        // 如果还没有这个MatchId的键，创建一个新数组
        if (!acc[match.MatchName]) {
          acc[match.MatchName] = [];
        }
        // 将当前match对象添加到对应MatchId的数组中
        acc[match.MatchName].push(match);
        return acc;
      }, {});

      // 将分组对象转换为数组
      return Object.keys(grouped).map(key => ({
        MatchName: key,
        Matches: grouped[key]
      }));
    },
    // 按Bo分组
    groupedByBo(ReturnMatchLog) {
      const grouped = ReturnMatchLog.reduce((acc, match) => {
        // 如果还没有这个MatchId的键，创建一个新数组
        if (!acc[match.Bo]) {
          acc[match.Bo] = [];
        }
        // 将当前match对象添加到对应MatchId的数组中
        acc[match.Bo].push(match);
        return acc;
      }, {});

      // 将分组对象转换为数组
      return Object.keys(grouped).map(key => ({
        Bo: 'Bo'+key,
        Matches: grouped[key]
      }));
    },
    // 按对抗分组
    groupedByArgs(ReturnMatchLog, args) {
      const grouped = ReturnMatchLog.reduce((acc, match) => {
        // 如果还没有这个MatchId的键，创建一个新数组
        if (!acc[match[args]]) {
          acc[match[args]] = [];
        }
        // 将当前match对象添加到对应MatchId的数组中
        acc[match[args]].push(match);
        return acc;
      }, {});

      // 将分组对象转换为数组
      return Object.keys(grouped).map(key => ({
        [args]: key,
        Matches: grouped[key]
      }));
    },
    calculateRankings(matches, attrib) {
      // console.log(matches);
      let scores = {};
      // 遍历所有比赛，累计每个玩家的分数
      matches.forEach(match => {
        // 检查玩家1是否已在对象中，如果没有则初始化
        if (!scores[match.Player1Id]) {
          scores[match.Player1Id] = {
            UserName:match.UserName1,NickName: match.Player1,Race:match.Race1,Level:match.Level1, score: 0,lose:0,Chance:0,Attrib:attrib
          };
        }
        // 检查玩家2是否已在对象中，如果没有则初始化
        if (!scores[match.Player2Id]) {
          scores[match.Player2Id] = {
            UserName:match.UserName2,NickName: match.Player2,Race:match.Race2,Level:match.Level2,score: 0,lose:0,Chance:0,Attrib:attrib
          };
        }
        // 累加玩家1和玩家2的分数
        scores[match.Player1Id].score += parseInt(match.Score1, 10);
        scores[match.Player1Id].lose += parseInt(match.Score2, 10);
        scores[match.Player2Id].score += parseInt(match.Score2, 10);
        scores[match.Player2Id].lose += parseInt(match.Score1, 10);
      });
      // 计算每个玩家的胜率
      Object.values(scores).forEach(player => {
        player.Chance=this.formatWinRate(player.score,player.lose);
        // console.log(`${player.UserName}: ${player.Chance}%`, typeof player.Chance);
      });
      // 将玩家分数对象转换为数组，并根据分数降序排序
      let rankings = Object.values(scores).sort((a, b) => {
        if (b.Chance==a.Chance){
          return b.score - a.score
        }else {
          return b.Chance - a.Chance;
        }
      });

      // 遍历排序后的数组，添加index属性
      rankings.forEach((player, index) => {
        player.index = index + 1; // 排名是从1开始的
        // console.log(player.index);
      });
      // 更新存储排名数据的变量
      this.playerScore = rankings;
      // console.log(this.playerScore);
      return rankings
    },

    calConfRankings(matches) {
      let scores = {};
      // 遍历所有比赛，累计每个玩家的分数
      matches.forEach(match => {
        // 检查玩家1是否已在对象中，如果没有则初始化
        if (!scores[match.Player1Id]) {
          scores[match.Player1Id] = {
            UserName:match.UserName1,NickName: match.Player1,Race:match.Race1,Level:match.Level1, Confrontation:match.Confrontation,score: 0,lose:0,Chance:0
          };
        }
        scores[match.Player1Id].score += parseInt(match.Score1, 10);
        scores[match.Player1Id].lose += parseInt(match.Score2, 10);
      });
      // 计算每个玩家的胜率
      Object.values(scores).forEach(player => {
        player.Chance=this.formatWinRate(player.score,player.lose);
      });
      // 将玩家分数对象转换为数组，并根据分数降序排序
      // let rankings = Object.values(scores).sort((a, b) => b.score - a.score);
      let rankings = Object.values(scores).sort((a, b) => b.Chance - a.Chance);

      // 更新存储排名数据的变量
      this.UserScore = this.UserScore.concat(rankings);
      // console.log(this.UserScore);
    },
    // 循环调用calConfRankings，生成选手每种对抗的数据
    processUserData(data){
      this.UserScore=[];
      data.forEach(item=>{
        this.calConfRankings(item.Matches);
      });
    },
    // 生成指定选手bo中的胜率
    calBoWins(matches, attrib) {
      let scores = {};
      // 遍历所有比赛，累计每个玩家的分数
      matches.forEach(match => {
        // total++;
        // 生成指定对象数据
        if (!scores[match.Player1Id]) {
          scores[match.Player1Id] = {
            UserName:match.UserName1,NickName: match.Player1,Race:match.Race1,Level:match.Level1, Confrontation:match.Confrontation,total:0,win:0,Attrib:attrib,winRate:0
          };
        }
        scores[match.Player1Id].total++;
        if(match.Result==1){
          scores[match.Player1Id].win++;
        }
      });
      // 计算每个玩家的胜率
      Object.values(scores).forEach(player => {
        player.winRate=this.forBoWinRate(player.total,player.win);
      });
      // 将玩家分数对象转换为数组，并根据分数降序排序
      let rankings = Object.values(scores).sort((a, b) => b.Chance - a.Chance);
      // 更新存储排名数据的变量
      this.UserBoScore = this.UserBoScore.concat(rankings);
      // console.log(this.UserBoScore);
      // return rankings
    },

    // 选手详情
    SendQueryMsg(){
      this.activeUserTable=1;
      UserQuery(this.Player1Id).then(res=>{
        // console.log(res)
        if('code' in res &&res.code==1000){
          this.MatchConfArr=this.groupedByArgs(res.data,'Confrontation');
          this.MatchBo2Arr=this.groupedByBo(res.data)
          // console.log(this.MatchBo2Arr);
          // this.processUserBoData(this.MatchBo2Arr)
          // 生成Bo中的指定用户信息
          // 初始化this.UserResultData=[];
          this.UserBoScore=[];
          this.MatchBo2Arr.forEach(match=>{
            this.calBoWins(match.Matches, match.Bo);
          });
          // console.log(this.ResultArr);
          this.processUserData(this.MatchConfArr)
          // 生成总排行榜中的指定用户信息
          this.userDataResult=this.findUserDataRes(this.userDataArr,this.SelectPlayerNickName,'NickName')
          // console.log(this.userDataResult);
          // 初始化this.UserResultData=[];
          this.UserResultData=[];
          // this.findUserData(this.calculateRankings(this.matchLog))
          this.ResultArr=this.calculateRankings(this.matchLog,'总排行榜');
          // console.log(this.ResultArr);
          this.findUserData(this.ResultArr, this.SelectPlayerNickName,"NickName")
          // 生成各项比赛中的指定用户信息

          this.MatchIDArr.forEach(match=>{
            // console.log(match);
            this.ResultArr=this.calculateRankings(match.Matches,match.MatchName)
            // console.log(this.ResultArr);
            this.findUserData(this.ResultArr, this.SelectPlayerNickName,"NickName");
          });
          // console.log(this.UserResultData);
          // 生成Bo中的指定用户信息
          this.MatchBoArr.forEach(match=>{
            this.findUserData(this.calculateRankings(match.Matches, match.Bo), this.SelectPlayerNickName,'NickName');
          });
          this.resMsg="查询成功"
        }else {
          this.resMsg="查询失败"
        }
      })
    },
    //选手所有排名和得分
    findUserData(playersData, userName,findKey) {
      // 初始化一个空数组来存储找到的用户数据
      // 遍历数组，寻找匹配的用户名
      playersData.forEach(player => {
        if (player[findKey] === userName) {
          // 如果找到了匹配的用户名，将该对象添加到userData数组中
          this.UserResultData=this.UserResultData.concat(player);
          // console.log(this.UserResultData);
        }
      });
    },
    findUserDataRes(playersData, userName,findKey) {
      // 初始化一个空数组来存储找到的用户数据
      this.ResultArr =[];
      // 遍历数组，寻找匹配的用户名
      playersData.forEach(player => {
        if (player[findKey] === userName) {
          // 如果找到了匹配的用户名，将该对象添加到userData数组中
          this.ResultArr.push(player)
        }
      });
      // console.log(this.ResultArr);
      return this.ResultArr
    },
  },
  watch:{
    SelectPlayerNickName(newNickName) {
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newNickName);
      // 如果找到了用户，更新 selectedUserId
      this.Player1Id = user ? user.id : null;
    },
  }
}
</script>
<style>
.content{
  width: 80%;
  /*height: 38px;*/
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  /*display: block;*/
}
table {
  border-collapse: collapse; /* 合并边框，以便表格之间没有空隙 */
  border: 1px solid #7C8497; /* 设置表格外边框的样式，颜色和宽度 */
  margin: 20px auto;
}
h1,h3,label{
  color:#C9D3DD ;
}
/* 为表格内的单元格设置边框 */
th,td{
  height: 30px;
}
th {
  border-bottom: 1px solid #7C8497; /* 设置单元格边框的样式，颜色和宽度 */
  width: 200px;
  color: #C9D3DD;
}
td{
  color: #C9D3DD;
}
#home_content{
  width: 80%;
  margin:0 auto;
}
#home_nav{
  /*width: 96%;*/
  margin: 0 auto;
  /*height: 50px;*/
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
li{
  line-height: 38px;
  list-style: none;
  /*padding: 4px;*/
  /*margin: 6px 0 6px 0;*/
  font-family: "Microsoft YaHei UI";
  font-size: 18px;
  float: left;
  text-align: center;
}
li span{
  /*width: 100%;*/
  height: 30px;
  /*margin: 20px;*/
  padding: 0 5px;
  cursor: pointer;
  color: #C9D3DD;
  /*border: solid 1px #42b983;*/
}
.active-tab{
  color: #FFC25A;
  border-bottom: #FFC25A solid 2px;
}

.odd_row{
  background-color: #20232C;
}
.even_row{
  background-color: #151922;
}
</style>