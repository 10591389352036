<template>
    <div id="app_content">
      <span v-show="authorization==1">欢迎您： {{ username }}</span>
      <span @click="logout()" v-show="authorization==1" class="logout" >退出</span>
      <nav>
        <router-link to="/">星火燎原|</router-link>
        <router-link to="/Sponsor">赞助商|</router-link>
        <router-link to="/login" v-show="authorization==0">登陆|</router-link>
        <router-link to="/PtoMatch" v-show="authorization==1">赛事报名|</router-link>
        <router-link to="/Vote" v-show="privilege==1">添加赞助商|</router-link>
        <router-link to="/PlayerSignUp" v-show="privilege===1">添加选手  |</router-link>
        <router-link to="/MatchSignUp"  v-show="privilege===1">添加赛事  |</router-link>
        <router-link to="/MatchLog"  v-show="privilege===1">添加比赛记录  |</router-link>
        <router-link to="/Modify"  v-show="privilege===1">修改记录  |</router-link>
        <router-link to="/reset">重置密码</router-link>
      </nav>
    </div>
  <div class="nav_img">
<!--    <img src="@/assets/images/tiao.png" alt="">-->
  </div>
    <router-view/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      privilege: 0, // 默认权限为0
      authorization: 0, // 默认权限为0
      username:'',
    };
  },
  provide() {
    return {
      privilege: this.privilege,
      authorization: this.authorization,
      username: this.username,
      setPrivilege: this.setPrivilege,
      setAuthorization: this.setAuthorization,
    };
  },
  created() {
    this.getPrivilege();
  },
  methods: {
    logout(){
      this.privilege = 0;
      this.authorization=0;
      window.sessionStorage.removeItem('Privilege');
      window.sessionStorage.removeItem('Authorization');
      window.sessionStorage.removeItem('userName');
      setTimeout(() => {
        window.sessionStorage.removeItem('Authorization')
        this.$router.push('/')
      }, 300)
      // this.$router.push('/')
    },
    setPrivilege(newPrivilege,newAuth,newName) {
      this.privilege = newPrivilege;
      this.authorization=newAuth;
      this.username=newName;
      // window.sessionStorage.setItem('Privilege', newPrivilege);
    },
    getPrivilege() {
      // 从sessionStorage中获取Privilege值
      const privilege = window.sessionStorage.getItem('Privilege');
      const authorization = window.sessionStorage.getItem('Authorization');
      const username = window.sessionStorage.getItem('userName');
      // 将字符串转换为数字
      this.privilege = privilege ? Number(privilege) : 0;
      if(authorization){
        this.authorization=1;
      }
      this.username=username||'';
    }
  }
}
</script>

<style>
body,div{
  margin: 0;
  padding: 0;
}
.nav_img{
  width: 100%;
  height: 68px;
  overflow: hidden; /* 超出部分隐藏 */
  background: url('@/assets/images/tiao.png') no-repeat center;
}
body{
  /*width: 100%;*/
  /*height: 100%;*/
  background-color: #000B17;
  background-image: url("@/assets/images/bj.jpg");
  background-repeat: no-repeat;
  /*background-size: contain;*/
  /*background-size: cover;*/
  /*background-position: center;*/
  /*background-size: auto 100%; !* 在移动端宽度缩小，高度保持100% *!*/
  /*overflow: hidden; !* 超出部分隐藏 *!*/
}
@media (max-width: 768px) {
  body{
    background-size: auto 100%; /* 在移动端宽度缩小，高度保持100% */
    overflow: hidden; /* 超出部分隐藏 */
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app_content{
  width: 100%;
  hieght: 80px;
  display: block;
  padding: 20px 0;
  /*padding-bottom: 60px;*/
}
#app_content nav,#app_content span {
  //padding: 30px;
  //width: 30%;
  //margin: 0 0 0 60%;
  color: #C9D3DD;
}
#app_content span{
  margin: 0 0 0 20px;
  line-height: 38px;
}
.logout{
  cursor: pointer;
}
nav a,#app_content span a {
  font-weight: bold;
  color: #C9D3DD;
}

nav a.router-link-exact-active {
  color: #FFC25A;
}
</style>
<script setup>
import ReSet from "@/views/ReSet.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import {left} from "core-js/internals/array-reduce";
</script>