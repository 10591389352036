<template>
  <div class="sponsor_table">
    <h1>赞助商</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <div class="table-responsive">
        <table>
          <tr>
            <th>序号</th>
            <th>赞助商名称</th>
            <th>赛事名称</th>
            <th>赞助金额</th>
          </tr>
          <template v-for="(item,index) in SponsorDate" :key="index" >
            <tr :class="{'odd_row': index % 2 !== 0, 'even_row': index % 2 === 0}">
              <td>{{ item.id }}</td>
              <td>{{ item.VoteName }}</td>
              <td>{{ item.MatchName}}</td>
              <td>{{ item.Money }}</td>
            </tr>
          </template>
        </table>
    </div>

  </div>
</template>

<script>
import {ref} from 'vue'
import {GetSponsorData} from "@/network/Money";
// import * as events from "events";

let SponsorDate = ref([])


export default {
  name: "Sponsor",
  created() {
    this.getData()
  },
  data(){
    let resMsg;
    return{
      resMsg:'',
      SponsorDate,
      admin:'',
    }
  },
  methods:{
    getData() {
      GetSponsorData().then(res => {
        this.admin = window.sessionStorage.getItem('userName');
        // console.log(this.admin);
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              SponsorDate.value=res.data
              console.log(SponsorDate);
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
  }
}
</script>

<style>
.sponsor_table{
  margin: 0 auto;
}
</style>